import { Route, Switch } from "react-router-dom";

import ImageList from "./list/ImageList";
import ImageView from "./view/ImageView";
import React from "react";

export function Images() {
  return (
    <Switch>
      <Route exact path="/admins/images/" component={ImageList} />
      <Route exact path="/admins/images/:imageId" component={ImageView} />
    </Switch>
  );
}
