import { createMuiTheme } from "@material-ui/core";

const DefaultTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#401D3E",
    },
    secondary: {
      main: "#F9BA0D",
    },
    background: {
      default: "#FFFFFF",
    },
    text: {
      primary: "#221f20",
    },
  },
  typography: {
    fontSize: 12,
    fontFamily: [
      "Poppins",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  overrides: {
    MuiButton: {
      label: {
        fontSize: "1rem",
      },
    },
  },
});

export default DefaultTheme;
