import {
  Action,
  Column,
  Localization,
  Table,
  TableEvent,
  TableIcons,
} from "./table/Table";
import React, { useCallback, useState } from "react";

import PopupForm from "./PopupForm";

export interface FormProps<RowData extends object> {
  data?: RowData;
  id?: string;
  onFinish: () => void;
}

export interface CrudComponentProps<RowData extends object> {
  title: string;
  type: string;
  columns: Column<RowData>[];
  data: RowData[];
  canAdd?: boolean;
  canEdit?: boolean;
  onDelete?: (row: RowData) => Promise<null | void>;
  onRowClick?: (
    event?: React.MouseEvent,
    rowData?: RowData,
    toggleDetailPanel?: (panelIndex?: number) => void
  ) => void;
  form?: React.ComponentType<FormProps<RowData>>;
  onUpdate?: () => Promise<null | void>;
  localization?: Localization;
  rowStyle?:
    | React.CSSProperties
    | ((data: RowData, index: number, level: number) => React.CSSProperties);
}

const getActions = (
  props: CrudComponentProps<any>,
  onRowAdd: TableEvent<any>,
  onRowEdit: TableEvent<any>
) => {
  const actions: Action<any>[] = [];
  if (props.canEdit && props.form) {
    actions.push({
      icon: () => <TableIcons.Edit />,
      tooltip: "Edit " + props.type,
      onClick: onRowEdit,
    });
  }
  if (props.canAdd && props.form) {
    actions.push({
      icon: () => <TableIcons.Add />,
      tooltip: "Add " + props.type,
      isFreeAction: true,
      onClick: onRowAdd,
    });
  }
  return actions;
};

export default function CrudComponent<RowData extends object>(
  props: CrudComponentProps<RowData>
) {
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState<RowData>();
  const [loading, setLoading] = useState(false);

  const onRowAdd = () => {
    setOpen(true);
    setItem(undefined);
  };

  const onRowEdit = (_: any, data: RowData) => {
    setOpen(true);
    setItem(data);
  };

  const onRowDelete = async (rowData: RowData) => {
    await props.onDelete?.(rowData);
  };

  const closeForm = useCallback(async () => {
    setOpen(false);
  }, []);

  const onFormFinish = async () => {
    closeForm();
    setLoading(true);
    await props.onUpdate?.();
    setLoading(false);
  };

  const actions: Action<RowData>[] = getActions(props, onRowAdd, onRowEdit);
  return (
    <>
      <PopupForm
        data={item}
        form={props.form}
        open={open}
        onClose={closeForm}
        title={`${item ? "Update" : "Add"} ${props.type}`}
        onFormFinish={onFormFinish}
      />
      <Table
        key={props.type}
        title={props.title}
        columns={props.columns}
        data={props.data}
        isLoading={loading}
        actions={actions}
        onRowClick={props.onRowClick}
        onRowDelete={props.onDelete && props.onUpdate ? onRowDelete : undefined}
        localization={props.localization}
        options={{
          actionsColumnIndex: -1,
          rowStyle: props.rowStyle,
        }}
      />
    </>
  );
}
