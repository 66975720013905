import { AuthContextProps, withAuthContext } from "../auth/withAppContext";
import { Redirect, Route, RouteProps } from "react-router-dom";

import React from "react";
import { UrlUtils } from "../../utils/urlUtils";
import { getItem } from "../../utils/sessionStorage";

const AdminRoute = withAuthContext(
  ({ component: Component, auth, ...rest }: AuthContextProps & RouteProps) => {
    if (!Component) {
      return null;
    }
    return (
      <Route
        {...rest}
        render={(props) =>
          getItem("user") ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/",
                search: UrlUtils.constructRedirectUrl(props),
              }}
            />
          )
        }
      ></Route>
    );
  }
);

export default AdminRoute;
