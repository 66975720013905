import { ToastOptions, toast } from "react-toastify";

export type NotificationOptions = {
  autoClose: boolean;
};
export class Notification {
  public static showApiError(err: any, defaultMessage: string) {
    const response = err?.response;
    const data = response?.data;
    console.log(data ?? response ?? err);
    const message: string = data?.["message"] ?? defaultMessage;
    Notification.showError(message);
    return null;
  }

  public static showError(message: string, options?: NotificationOptions) {
    toast.error(message, this.convertToToastOptions(options));
  }

  public static showSuccess(message: string, options?: NotificationOptions) {
    toast.success(message, this.convertToToastOptions(options));
  }

  public static showInfo(message: string, options?: NotificationOptions) {
    toast.success(message, this.convertToToastOptions(options));
  }

  private static convertToToastOptions(options?: NotificationOptions) {
    const toastOptions: ToastOptions = {};
    if (options?.autoClose === false) {
      toastOptions.autoClose = false;
    }
    toastOptions.bodyClassName = "toast-body";
    return toastOptions;
  }
}
