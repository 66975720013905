import "./AdminForms.scss";

import ResponsiveDrawer, { TabType } from "../../common/ResponsiveDrawer";

import CategoryIcon from "@material-ui/icons/Category";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { Images } from "../images/Images";
import Logout from "../../auth/logout/Logout";

export const AdminTabs: TabType[] = [
  {
    id: "images",
    icon: CategoryIcon,
    label: "Images",
    component: Images,
  },
  {
    id: "logout",
    icon: ExitToAppIcon,
    label: "Logout",
    component: Logout,
  },
];

export default function AdminLayout() {
  return (
    <>
      <ResponsiveDrawer
        tabs={AdminTabs}
        routePrefix="/admins/"
        defaultRoute="dashboard"
      >
        Masanodes Admin Panel
      </ResponsiveDrawer>
    </>
  );
}
