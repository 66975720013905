import "react-toastify/dist/ReactToastify.css";

import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import AdminLayout from "./components/admin/layout/AdminLayout";
import AdminRoute from "./components/admin/AdminRoute";
import AuthProvider from "./components/auth/AuthProvider";
import DateFnsUtils from "@date-io/date-fns";
import DefaultTheme from "./utils/defaultTheme";
import Logout from "./components/auth/logout/Logout";
import Main from "./components/main/Main";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import React from "react";
import { ThemeProvider } from "@material-ui/core";
import { toast } from "react-toastify";

toast.configure({
  position: "top-center",
  autoClose: 5000,
  draggable: false,
  closeOnClick: false,
});

function App() {
  return (
    <ThemeProvider theme={DefaultTheme}>
      <AuthProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Router>
            <Switch>
              <Route exact path="/" component={Main} />
              <Route exact path="/admins/logout" component={Logout} />
              <AdminRoute path="/admins" component={AdminLayout} />
            </Switch>
          </Router>
        </MuiPickersUtilsProvider>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
