import React, { useEffect, useState } from "react";

import { Api } from "../../utils/api";
import { AuthDto } from "./AuthDto";
import { RouteProps } from "react-router-dom";

export type AuthType = { loaded: boolean; user?: AuthDto };

export type AuthContextType = {
  auth: AuthType;
  updateAuth: () => void;
};

export const AuthContext = React.createContext<AuthContextType>({
  auth: { loaded: false },
  updateAuth: () => {},
});

export default function AuthProvider(props: RouteProps) {
  const [auth, setAuth] = useState<AuthType>({ loaded: false });

  const getLoggedInUser = () => {
    Api()
      .get("/me")
      .then((res) => {
        setAuth({ loaded: true, user: res.data });
      })
      .catch((err) => {
        console.log(err.response?.data);
        setAuth({ loaded: true });
      });
  };

  // componentDidMount
  useEffect(() => {
    getLoggedInUser();
  }, []);

  return (
    <AuthContext.Provider value={{ auth, updateAuth: getLoggedInUser }}>
      {auth.loaded && props.children}
    </AuthContext.Provider>
  );
}
