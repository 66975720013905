import "./Login.scss";

import { AuthContextProps, withAuthContext } from "../withAppContext";
import { Button, Paper, TextField, Typography } from "@material-ui/core";
import React, { FormEvent, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getItem, setItem } from "../../../utils/sessionStorage";

import { Api } from "../../../utils/api";
import { Notification } from "../../../utils/notification";
import { UrlUtils } from "../../../utils/urlUtils";

const Login = withRouter(
  withAuthContext((props: AuthContextProps & RouteComponentProps) => {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [password, setPassword] = useState("");

    const user = getItem("user");

    const login = (e: FormEvent) => {
      e.preventDefault();
      Api()
        .post(`/admins/login`, { phoneNumber, password })
        .then((res) => {
          setItem("user", JSON.stringify(res.data));
          props.history.push(UrlUtils.getRedirectUrl(props, "/admins"));
        })
        .catch((err) => {
          Notification.showApiError(err, "Unable to login");
        });
    };

    useEffect(() => {
      if (getItem("user")) {
        props.history.push(UrlUtils.getRedirectUrl(props, "/admins"));
      }
    }, [props, user]);

    return (
      <Paper elevation={0} className="login-container">
        <Typography variant="h4" className="title">
          Admin Login
        </Typography>
        <form onSubmit={login}>
          <TextField
            label="Phone Number"
            type="text"
            name="phone"
            fullWidth
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <TextField
            label="Password"
            type="password"
            name="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            size="large"
          >
            Log in
          </Button>
        </form>
      </Paper>
    );
  })
);

export default Login;
