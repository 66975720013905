import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@material-ui/core";
import React, { useCallback } from "react";

import CloseIcon from "@material-ui/icons/Close";
import { FormProps } from "./CrudComponent";

export interface PopupFormProps<RowData extends object> {
  form?: React.ComponentType<FormProps<RowData>>;
  title: string;
  data?: RowData;
  id?: string;
  open: boolean;
  onClose?: () => void;
  onFormFinish?: () => Promise<null | void>;
}

export default function PopupForm<RowData extends object>(
  props: PopupFormProps<RowData>
) {
  const { form: Component, onFormFinish } = props;

  const onFinish = useCallback(async () => {
    await onFormFinish?.();
  }, [onFormFinish]);

  if (Component) {
    return (
      <Dialog open={props.open} onClose={onFormFinish}>
        <DialogTitle disableTypography={true}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4">{props.title}</Typography>
            {props.onClose ? (
              <IconButton onClick={props.onClose}>
                <CloseIcon />
              </IconButton>
            ) : null}
          </Box>
        </DialogTitle>
        <DialogContent>
          <Component id={props.id} data={props.data} onFinish={onFinish} />
        </DialogContent>
      </Dialog>
    );
  } else {
    return null;
  }
}
