import { Button, TextField, createStyles, makeStyles } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import { FileUtils } from "../../../utils/FileUtils";
import { FormProps } from "../../common/CrudComponent";
import { GalleryType } from "./list/ImageList";
import { Notification } from "../../../utils/notification";
import axios from "axios";
import { useHistory } from "react-router-dom";

const useStyle = makeStyles(() =>
  createStyles({
    btn: {
      height: "fit-content",
      width: "fit-content",
      padding: "1%",
    },
    btnWithMargin: {
      marginRight: "2%",
    },
  })
);

export default function ImageForm(props: FormProps<GalleryType>) {
  const [name, setName] = useState<string>("");
  const [file, setFile] = useState<any>(null);
  const classes = useStyle();
  const history = useHistory();
  const url = process.env.REACT_APP_API_URL;

  const fileDialogChanged = useCallback(async (event) => {
    console.log("Image form onchange", event.target.files[0]);
    setFile(event.target.files[0]);
  }, []);

  const onSubmit = async () => {
    const formData = new FormData();
    if (file && name) {
      formData.append("file", file);
      formData.append("name", name);

      try {
        await axios.post(`${url}images/`, formData);
        Notification.showSuccess("Successfully Added Image");
      } catch (error) {
        console.log("error", error);
        Notification.showApiError(
          error,
          "Something went wrong while Adding Image"
        );
      } finally {
        props.onFinish();
      }
    }
  };

  const onUpdate = async () => {
    const formData = new FormData();
    if (file) {
      formData.append("file", file);
    }
    formData.append("name", name);

    try {
      await axios.put(`${url}images/image/${props.data?.id}`, formData);
      Notification.showSuccess("Successfully Updated Image");
    } catch (error) {
      console.log("error", error);
      Notification.showApiError(
        error,
        "Something went wrong while Adding Image"
      );
    } finally {
      props.onFinish();
    }
  };

  const onDelete = async () => {
    try {
      if (props.data?.id) {
        await axios.delete(`${url}images/image/${props.data?.id}`);
        Notification.showSuccess("Successfully Deleted Image");
      }
    } catch (error) {
      console.log("error", error);
      Notification.showApiError(
        error,
        "Something went wrong while Adding Image"
      );
    } finally {
      history.push("/admins/images");
    }
  };

  const onAddingNewImage = useCallback(() => {
    FileUtils.openFileDialog(fileDialogChanged);
  }, [fileDialogChanged]);

  useEffect(() => {
    if (props.data) {
      setName(props.data?.name ?? "");
    }
  }, [props.data]);

  return (
    <div className="form-container">
      <form>
        <TextField
          label="Name"
          type="text"
          name="name"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <Button
          variant="contained"
          color="secondary"
          size="large"
          startIcon={<AddCircleIcon />}
          onClick={onAddingNewImage}
          className={`${classes.btnWithMargin}`}
        >
          Add
        </Button>

        <Button
          onClick={() => {
            if (props.data) {
              onUpdate();
            } else {
              onSubmit();
            }
          }}
          variant="contained"
          color="primary"
          size="large"
          className={props.data ? classes.btnWithMargin : ""}
        >
          {props.data ? "Update" : "Submit"}
        </Button>

        {props.data && (
          <Button
            onClick={onDelete}
            variant="contained"
            color="primary"
            size="large"
            className={props.data ? classes.btnWithMargin : ""}
          >
            Delete
          </Button>
        )}
      </form>
    </div>
  );
}
