import { RouteProps } from "react-router-dom";

export class UrlUtils {
  static REDIRECT_KEY = "redirect_url";
  static getParam(props: RouteProps, key: string) {
    const params = new URLSearchParams(props.location?.search);
    return params.get(key);
  }

  static getRedirectUrl(props: RouteProps, defaultRoute: string) {
    return this.getParam(props, this.REDIRECT_KEY) || defaultRoute;
  }

  static constructRedirectUrl(props: RouteProps) {
    const path = props.location?.pathname;
    if (path) {
      return "?" + this.REDIRECT_KEY + "=" + path;
    } else {
      return "";
    }
  }
}
