import {
  Box,
  Button,
  Card,
  CardContent,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";

import EditIcon from "@material-ui/icons/Edit";
import { GalleryType } from "../list/ImageList";
import ImageForm from "../ImageForm";
import PopupForm from "../../../common/PopupForm";
import axios from "axios";
import { useParams } from "react-router-dom";

const useStyle = makeStyles(() =>
  createStyles({
    root: {
      minWidth: 275,
      marginBottom: "4%",
    },
    marginBottom: {},
    info: {
      fontSize: "18px",
      marginTop: "1%",
      marginLeft: "1%",
      marginBottom: 0,
    },
    heading: {
      display: "inline-block",
    },
    img: {
      marginLeft: "1%",
      height: "80px",
      width: "80px",
    },
    btn: {
      height: "fit-content",
      marginTop: "2%",
    },
  })
);

export default function ImageView() {
  const classes = useStyle();
  const params = useParams<any>();
  const [image, setImage] = useState<GalleryType>();
  const [open, setOpen] = useState<boolean>(false);
  const { imageId } = params;

  const getImage = useCallback(async () => {
    const url = process.env.REACT_APP_API_URL;
    const result = await axios.get<GalleryType>(
      `${url}images/image/${imageId}`
    );
    setImage(result.data);
  }, [imageId]);

  const closeForm = () => {
    setOpen(false);
  };

  const onFormFinish = async () => {
    closeForm();
    await getImage();
  };

  useEffect(() => {
    getImage();
  }, [getImage]);

  return (
    <Card className={classes.root}>
      <CardContent>
        {image && (
          <PopupForm
            data={image}
            form={ImageForm}
            open={open}
            onClose={closeForm}
            title={"Update Image - " + image.name}
            onFormFinish={onFormFinish}
          />
        )}
        <Box display="flex" justifyContent="space-between">
          <h1>Image</h1>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            startIcon={<EditIcon />}
            className={classes.btn}
            onClick={() => setOpen(!open)}
          >
            Edit
          </Button>
        </Box>
        {image && (
          <Box display="flex" flexDirection="column">
            <Box className={classes.marginBottom} display="flex">
              <h4>Name: </h4>
              <p className={classes.info}>{image.name}</p>
            </Box>
            <Box display="flex" alignItems="center">
              <h4 className={classes.heading}>Image: </h4>
              <img
                className={classes.img}
                src={image.imageURL}
                alt="GalleryImage"
              />
            </Box>
          </Box>
        )}
      </CardContent>
    </Card>
  );
}
