import {
  Link,
  NavLink,
  Redirect,
  Route,
  RouteProps,
  Switch,
} from "react-router-dom";
import React, { ComponentType } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import AppBar from "@material-ui/core/AppBar";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      height: "100%",
    },
    drawer: {
      [theme.breakpoints.up("sm")]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up("sm")]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
      backgroundColor: "black",
    },
    appLogo: {
      width: "60%",
      textAlign: "center",
    },
    appBarContent: {
      flexGrow: 1,
      backgroundColor: "black",
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    drawerItem: {
      textAlign: "center",
      padding: theme.spacing(2),
      backgroundColor: "black",
    },
    drawerItemFooter: {
      textAlign: "center",
      padding: theme.spacing(2),
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      width: `calc(100% - ${drawerWidth}px)`,
      padding: theme.spacing(3),
      position: "relative",
    },
    menus: {
      flexGrow: 1,
      "& > a": {
        display: "block",
      },
      "& > .active": {
        backgroundColor: theme.palette.secondary.main,
      },
    },
    backButton: {
      position: "absolute",
      left: 0,
      zIndex: 99999,
    },
  })
);

export type TabType = {
  id: string;
  icon: ComponentType;
  label: string;
  component: ComponentType;
};

export interface ResponsiveDrawerProps {
  tabs: TabType[];
  routePrefix: string;
  defaultRoute: string;
}

export default function ResponsiveDrawer({
  children,
  tabs,
  routePrefix,
  defaultRoute,
}: ResponsiveDrawerProps & RouteProps) {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  const closeDrawer = () => setMobileOpen(false);

  const history = useHistory();

  const drawer = (
    <>
      <div className={classes.drawerItem}>
        <Link to={routePrefix}>
          <img
            src={"http://masanodes.com/images/logo.png"}
            alt="Fabo Logo"
            className={classes.appLogo}
          />
        </Link>
      </div>
      <Divider />
      <List className={classes.menus}>
        {tabs.map((tab, index) => (
          <NavLink
            key={index}
            to={routePrefix + tab.id}
            activeClassName="active"
            onClick={closeDrawer}
          >
            <ListItem button>
              <ListItemIcon>
                <tab.icon />
              </ListItemIcon>
              <ListItemText primary={tab.label} />
            </ListItem>
          </NavLink>
        ))}
      </List>
      <Divider />
      <div className={classes.drawerItemFooter}>
        Faboproperties.com &copy; 2020
      </div>
    </>
  );

  const goBack = () => {
    history.goBack();
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" noWrap className={classes.appBarContent}>
            {children}
          </Typography>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor="right"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <IconButton onClick={goBack} className={classes.backButton}>
          <ArrowBackIosIcon />
        </IconButton>
        <Switch>
          {tabs.map((tab, index) => (
            <Route
              key={index}
              path={routePrefix + tab.id}
              component={tab.component}
            ></Route>
          ))}
          <Route
            path="*"
            render={() => <Redirect to={routePrefix + defaultRoute} />}
          ></Route>
        </Switch>
      </main>
    </div>
  );
}
