import "./Header.scss";

import { Button, Toolbar, Typography } from "@material-ui/core";

import { Link } from "react-router-dom";
import React from "react";

const Header = () => {
  return (
    <Toolbar className="header" style={{ backgroundColor: "black" }}>
      <img
        src={"http://masanodes.com/images/logo.png"}
        alt="Fabo Logo"
        className="logo"
      />
      <Typography variant="h4" className="title" style={{ color: "white" }}>
        Masanodes Admin Panel
      </Typography>
      <Link to="/admin">
        <Button style={{ color: "white" }}>Login</Button>
      </Link>
    </Toolbar>
  );
};

export default Header;
