import React, {
  ForwardRefExoticComponent,
  ReactElement,
  ReactNode,
  RefAttributes,
  forwardRef,
} from "react";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import MaterialTable from "material-table";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";

export type Icon = ForwardRefExoticComponent<RefAttributes<SVGSVGElement>>;

export interface Icons {
  Add: Icon;
  Check: Icon;
  Clear: Icon;
  Delete: Icon;
  DetailPanel: Icon;
  Edit: Icon;
  Export: Icon;
  Filter: Icon;
  FirstPage: Icon;
  SortArrow: Icon;
  LastPage: Icon;
  NextPage: Icon;
  PreviousPage: Icon;
  ResetSearch: Icon;
  Search: Icon;
  ThirdStateCheck: Icon;
  ViewColumn: Icon;
}

export const TableIcons: Icons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export interface Column<RowData extends object> {
  field: keyof RowData;
  title: string;
  render?: (data: RowData, type: "row" | "group") => ReactNode;
  sorting?: boolean;
  customSort?: (
    data1: RowData,
    data2: RowData,
    type: "row" | "group"
  ) => number;
}

export type TableEvent<RowData extends object> = (
  event: any,
  data: RowData | RowData[]
) => void;

export interface Action<RowData extends object> {
  icon: () => ReactElement<Icon>;
  tooltip: string;
  onClick: TableEvent<RowData>;
  isFreeAction?: boolean;
  disabled?: boolean;
  hidden?: boolean;
}

export interface Option {
  actionsColumnIndex?: number;
  rowStyle?:
    | React.CSSProperties
    | ((data: any, index: number, level: number) => React.CSSProperties);
}

export interface Localization {
  body?: {
    editRow?: {
      saveTooltip?: string;
      cancelTooltip?: string;
      deleteText?: string;
    };
    addTooltip?: string;
    deleteTooltip?: string;
    editTooltip?: string;
  };
}

export interface TableProps<RowData extends object> {
  title: string;
  columns: Column<RowData>[];
  data: RowData[];
  actions?: (Action<RowData> | ((rowData: RowData) => Action<RowData>))[];
  isLoading?: boolean;
  options?: Option;
  onRowClick?: (
    event?: React.MouseEvent,
    rowData?: RowData,
    toggleDetailPanel?: (panelIndex?: number) => void
  ) => void;
  onRowDelete?: (oldData: RowData) => Promise<any>;
  localization?: Localization;
}

export function Table<RowData extends object>(props: TableProps<RowData>) {
  return (
    <MaterialTable
      icons={TableIcons}
      columns={props.columns}
      data={props.data}
      title={props.title}
      actions={props.actions}
      isLoading={props.isLoading}
      options={props.options}
      editable={{ onRowDelete: props.onRowDelete }}
      localization={props.localization}
      onRowClick={props.onRowClick}
    />
  );
}
