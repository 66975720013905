import Cookies from "js-cookie";
import axios from "axios";

export const Api = () => {
  const csrfToken = Cookies.get("CSRF-Token") || "";
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    responseType: "json",
    headers: {
      "CSRF-Token": csrfToken,
    },
    withCredentials: true,
  });
};
