import { AuthContext, AuthContextType } from "./AuthProvider";
import React, { ComponentType, FC } from "react";

import { Subtract } from "utility-types";

export type AuthContextProps = AuthContextType;

export const withAuthContext = <P extends AuthContextProps>(
  Component: ComponentType<P>
) => {
  const wrapperComponent: FC<Subtract<P, AuthContextProps>> = (props) => {
    return (
      <AuthContext.Consumer>
        {(auth) => <Component {...(props as P)} {...auth} />}
      </AuthContext.Consumer>
    );
  };
  return wrapperComponent;
};
