import "./Main.scss";

import Header from "../layout/Header/Header";
import Login from "../auth/login/Login";
import React from "react";

function Main() {
  return (
    <div className="app-main">
      <Header />
      <Login />
    </div>
  );
}

export default Main;
