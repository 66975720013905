export class FileUtils {
  static openFileDialog = (
    callback: any,
    accept: string = ".jpg,.gif,.svg,.png"
  ) => {
    var inputElement = document.createElement("input");
    inputElement.type = "file";
    inputElement.accept = accept; // Note Edge does not support this attribute
    inputElement.addEventListener("change", callback);

    inputElement.dispatchEvent(new MouseEvent("click"));
  };
}
