import React, { useCallback, useEffect, useState } from "react";
import { createStyles, makeStyles } from "@material-ui/core";

import { Column } from "../../../common/table/Table";
import CrudComponent from "../../../common/CrudComponent";
import ImageForm from "../ImageForm";
import axios from "axios";
import { useHistory } from "react-router-dom";

const useStyle = makeStyles(() =>
  createStyles({
    image: {
      height: "80px",
      width: "80px",
    },
  })
);

export interface GalleryType {
  imageURL: string;
  name: string;
  id: string;
}

export default function ImageList() {
  const [gallery, setGallery] = useState<GalleryType[]>([]);
  const classes = useStyle();
  const history = useHistory();

  const columns: Column<GalleryType>[] = [
    {
      field: "name",
      title: "Name",
    },
    {
      title: "URL",
      field: "imageURL",
      render: (data: GalleryType) => {
        if (data.imageURL) {
          return (
            <img
              className={classes.image}
              src={data.imageURL}
              alt="galleryImg"
            />
          );
        }
      },
    },
  ];

  const getGallery = useCallback(async () => {
    const url = process.env.REACT_APP_API_URL;
    const result = await axios.get<GalleryType[]>(`${url}images/`);
    setGallery(result.data);
  }, []);

  const goToImage = useCallback(
    (data: GalleryType | undefined) => {
      if (data) {
        history.push(`/admins/images/${data.id}`);
      }
    },
    [history]
  );

  useEffect(() => {
    getGallery();
  }, [getGallery]);

  return (
    <CrudComponent
      title="Images"
      type="image"
      data={gallery}
      columns={columns}
      canEdit={false}
      canAdd={true}
      form={ImageForm}
      onUpdate={async () => await getGallery()}
      onRowClick={(_, rowData: GalleryType | undefined) => goToImage(rowData)}
    />
  );
}
