import { AuthContextProps, withAuthContext } from "../withAppContext";
import React, { useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { Api } from "../../../utils/api";
import { Notification } from "../../../utils/notification";
import { deleteItem } from "../../../utils/sessionStorage";

const Logout = withRouter(
  withAuthContext(
    ({ history, auth, updateAuth }: AuthContextProps & RouteComponentProps) => {
      useEffect(() => {
        Api()
          .post("/auth/logout")
          .then(() => {
            deleteItem("user");
            history.push("/");
          })
          .catch((err) => {
            Notification.showApiError(err, "Unable to logout");
          });
      }, [auth, history]);

      return <div>Logging Out...</div>;
    }
  )
);

export default Logout;
